import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const axios = require('axios');

class BlogRoll extends React.Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    async sendData(posts) {
        // console.log(posts);
        // console.log(window.location.href);
        // console.log(window.location.pathname);
        let curSiteUrl = window.location.href
        let finalSiteUrl = curSiteUrl.replace(window.location.pathname, "");
        let finalArr = [];
        let finalObj = {}
        posts.forEach(function (i, v) {
            // console.log(i['node']['frontmatter']['hiddenpost'])
            if (i['node']['frontmatter']['hiddenpost'] === true) {
                let dataObj = {};
                finalObj.gradeId = i['node']['frontmatter']['grade'] || '6'
                finalObj.boardId = i['node']['frontmatter']['board'] || 'ssc'
                dataObj.title = i['node']['frontmatter']['title'];
                dataObj.external_url = finalSiteUrl + i['node']['fields']['slug'];
                dataObj.thumbnail_url = i['node']['frontmatter']['featuredimage'] !== null ? finalSiteUrl + i['node']['frontmatter']['featuredimage']['childImageSharp']['fluid']['src'] : ''
                dataObj.short_description = i['node']['excerpt']
                finalArr.push(dataObj);
            }
        });
        finalObj.posts = finalArr
        console.log(finalObj);
        // let sendData = await axios.post('https://8ytwl5trrl.execute-api.ap-south-1.amazonaws.com/PROD/blogposts', finalObj);
        // console.log('SEND DATA', sendData);
    }
    componentDidMount() {
        const { data } = this.props
        const { edges: posts } = data.allMarkdownRemark
        this.sendData(posts);
    }

    render() {
        const { data } = this.props
        const { edges: posts } = data.allMarkdownRemark
        return (
            <div className="row is-multiline">
                {posts &&
                    posts.map(({ node: post }) => (
                        <div className="is-parent col-md-4" key={post.id}>
                            {/* {post.frontmatter.hiddenpost !== false &&  */}
                                <div  key={post.id}>
                                    <article
                                        className={`blog-list-item tile is-child box notification ${
                                            post.frontmatter.featuredpost ? 'is-featured' : ''
                                            }`}
                                    >
                                        <header>
                                            {post.frontmatter.featuredimage ? (
                                                <div className="featured-thumbnail">
                                                    <PreviewCompatibleImage
                                                        imageInfo={{
                                                            image: post.frontmatter.featuredimage,
                                                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                            <p className="post-meta">
                                                <Link
                                                    className="title has-text-primary is-size-4"
                                                    to={post.fields.slug}
                                                >
                                                    {post.frontmatter.title}
                                                </Link>
                                                <span> &bull; </span>
                                                <span className="subtitle is-size-5 is-block">
                                                    {post.frontmatter.date}
                                                </span>
                                            </p>
                                        </header>
                                        <p>
                                            {post.excerpt}
                                            <Link className="button" to={post.fields.slug}>
                                                Keep Reading →
                                    </Link>
                                        </p>
                                    </article>
                                </div>
                            {/* // } */}
                         </div> 
                    ))}
            </div>
        )
    }
}

BlogRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}


export default () => (
    <StaticQuery
        query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" },hiddenpost:{eq:false}} }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                hiddenpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
        render={(data, count) => <BlogRoll data={data} count={count} />}
    />
)
